import wx from "weixin-js-sdk";
import store from "@/store";
import { getJsApiSign } from "@/api/user";
// import { Toast,Notify} from "vant";

export default {
  /* 初始化wxjsdk各种接口 */
  init(apiList = []) {
    // 需要使用的api列表
    return new Promise((resolve, reject) => {
      // console.log(store.getters.initLink)
      let user = JSON.parse(localStorage.getItem('user-info'))
      // console.log('支付:参数 响应',store.getters.initLink,localStorage.getItem('resFlog1'),user.openid, apiList)
      getJsApiSign(store.getters.initLink, apiList,localStorage.getItem('resFlog1')||user.officialId,user.openid).then(res => {
        // console.log('支付',store.getters.initLink,localStorage.getItem('resFlog1'),user.openid, apiList,res)
        if (res.appId) {
          wx.config({
            debug: false,
            appId: res.appId,
            timestamp: res.timestamp,
            nonceStr: res.nonceStr,
            signature: res.signature,
            jsApiList: res.jsApiList
          });
          wx.ready(res => {
            // 微信SDK准备就绪后执行的回调。
            resolve(wx, res);
          });
        } else {
          reject(res);
        }
      });
    });
  }
};
