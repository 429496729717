<template>
  <div></div>
  <div class="main">
    <van-row>
      <van-image width="100%" height="100%" src="/images/bg.png" />
    </van-row>
    <van-row type="flex" justify="center" class="bg-top">
      <van-col span="22"
        ><van-field
          v-model="iccid"
          size="large"
          placeholder="请输入ICCID或扫码登录"
          autofocus
          :color="iccid == '8986112128103181220'||'xjm'?'#f90':'#000'"
          :style="{borderRadius: '10px',overflow: 'hidden'}"
          clearable
      /></van-col>
    </van-row>
    <!-- <span v-show="iccid == '8986112128103181220'" style="font-size: 10px;">开发专用账号</span> -->
    <van-row type="flex" justify="center" class="bg-top">
      <van-col span="22"
        ><van-button
          type="primary"
          size="large"
          :color="((this.iccid == '8986112128103181220') || (this.iccid == 'xjm')||(this.iccid == '8986112128103182909') || (this.iccid == 'zxm'))?'#f90':'#1989fa'"
          round
          @click="login"
          >立即登录</van-button
        ></van-col
      >
    </van-row>
    <van-row type="flex" justify="center" class="bg-top">
      <van-col span="22"
        ><van-button
          type="primary"
          size="large"
          color="#868383"
          icon="scan"
          round
          @click="scanLogin"
          >扫码登录</van-button
        ></van-col
      >
    </van-row>
    <!-- <van-row type="flex" justify="center" class="bg-top">
      <van-col span="22"
        ><van-button
          type="primary"
          size="large"
          color="#868383"
          icon="scan"
          round
          @click="showAll"
          >显示所有接口</van-button
        ></van-col
      >
    </van-row>
    <van-row type="flex" justify="center" class="bg-top">
      <van-col span="22"
        ><van-button
          type="primary"
          size="large"
          color="#868383"
          icon="scan"
          round
          @click="down"
          >关闭</van-button
        ></van-col
      >
    </van-row>
    <van-row type="flex" justify="center" class="bg-top">
      <van-col span="22"
        ><van-button
          type="primary"
          size="large"
          color="#868383"
          icon="scan"
          round
          @click="toFriends"
          >分享给朋友</van-button
        ></van-col
      >
    </van-row> -->
    <div style="padding: 10px;">
      <p>温馨提示</p>
      <p style="font-size: 10px;">1.本卡只在本公众号充值有效</p>
      <p style="font-size: 10px;">2.物联卡需充值和实名后方可使用</p>
      <p style="font-size: 10px;">3.网络延迟导致异常情况请尝试重新进入</p>
      <p style="font-size: 10px;">4.如遇问题请联系公众号客服处理</p>
    </div>
  </div>
</template>

<script>
import wechatUtil from "@/utils/wechatUtil";
import axios from "axios";
import { setUserIccid } from "@/api/card";
  // import Cookies from "js-cookie";
import store from "@/store";
import { Dialog,Notify,Toast } from "vant";
export default {
  name: "index2.vue",
  data() {
    return {
      redirect: undefined,
      iccid: "",
      showAll:false,
      wxId:null,
      isOffid:null,
      iccids:[],
    };
  },
  created () {
    let user = JSON.parse(localStorage.getItem('user-info'))
    console.log(user,user+'1' == 'null1',user == null)
    
    sessionStorage.setItem('loginRef',1)//防止登录页面刷新导致code失效 01api错误
    // console.log('indexxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx')
    // let user = JSON.parse(localStorage.getItem('user-info'))
    // if(user) {this.iccid =  user.iccid || localStorage.getItem('cardId')}else{this.iccid = ''}
    this.iccid = localStorage.getItem('cardId') || ''
    if(this.iccid+'1' == 'null1'){
      this.iccid = ''
    }
    let timeStamp = new Date()
    if(!timeStamp){
        alert('1002系统错误,请截图联系后台')
    }
    let url= window.location.href + '?timeStamp=' + timeStamp.getTime();
    let wxgzh = url.substring(url.lastIndexOf('/wx') + 0);
    // 是不是带有key
    let flagReg= /key=1/;
    let flagReg2= /key=2/;
    let offidReg= /wx772beb18b653aaaf/;//处理硬件贴的二维码公众号写死的问题
    let isKey = wxgzh.match(flagReg);
    this.isOffid = wxgzh.match(offidReg);
    localStorage.setItem('isKey',isKey||'key=2')
    localStorage.setItem('isOffid',this.isOffid)
    console.log('wxgzh',wxgzh,flagReg,isKey)
    
    // 拿到路径上微信公众号编码
    let matchReg2= /wx.*?(?=\?)/;
    let wxId2 = wxgzh.match(matchReg2);
    //防止一开始没有vuex里的offId，导致路由前置守卫那里存空，这里提前重复操作，把链接的offId存入
    localStorage.setItem('resFlog1',wxId2[0])

    // console.log('路径---------',flagReg,offidReg,'带有key吗:',isKey,'带有aaaf吗:',isOffid)


    // 拿到路径上微信公众号编码
    // let matchReg = /wx.*?(?=\?iccid)/gi;//链接带iccid情况
    // let matchReg2= /wx.*?(?=\?)/gi;
    // let wxId = wxgzh.match(matchReg);
    // let wxId2 = wxgzh.match(matchReg2);
    // let iccid = this.GetQueryString("iccid",wxgzh)// 拿到iccid
    // let appid = this.GetQueryString("appid",wxgzh)// 拿到appid
    // console.log('wxgzh'+wxgzh)
    // console.log('带iccid链接的公众号：'+wxId)
    // console.log('公众号2：'+wxId2)
    
    // localStorage.setItem('resFlog1',wxId2)//防止一开始没有vuex里的offId，导致路由前置守卫存空，这里重复操作，把链接的offId存入
    // console.log('iccid：'+iccid)
    // this.wxId = wxId?wxId:wxId2
    // 携带iccid的话 实现自动登录
    // if(iccid){
    //   this.iccid = iccid
    //   this.showAll = false
    //   this.login()
    // }
    this.getIccids()
  },
  methods: {
    isHave(arr,val) {
      // let arr1 = Array.from(arr)
      return arr.includes(val);
    },
    getIccids(){
      const url = '/iccids.txt'; // 替换为真正的 .txt 文件路径
      axios.get(url).then((response) => {
        this.iccids = response.data.split('\r\n')
        // console.log('相关文件:'+url, response.data,this.iccids);
      }).catch((error) => {
        console.log('未找到相关文件:'+url, error);
      });
    },
    // 封装一个拿去路径参数的函数11
    GetQueryString(name) {
        let reg = new RegExp("(^|&)"+ name +"=([^&]*)(&|$)");
        let r = window.location.search.substr(1).match(reg);
        if(r!=null)return  unescape(r[2]); return null;
    },
    login() {
      if(this.iccid == 'xjm'){this.iccid = '8986112128103181220'}
      if(this.iccid == 'zxm'){this.iccid = '8986112128103182909'}
      let ishave= this.isHave(this.iccids,this.iccid)
      if(ishave && this.isOffid!='wx772beb18b653aaaf'){
        location.href ='http://card.wxd-iot.com/wx772beb18b653aaaf?key=1'
      }
      if(!this.iccid){
        Toast({
          message: "请输入iccid",
          position: "top",
        });
      }else{
          // this.iccid = this.iccid.replace(' ','')
          this.iccid = this.iccid.replace(/\s*/g,"");
          localStorage.setItem('cardId',this.iccid)
          localStorage.setItem('realNameFlag',1)
          localStorage.setItem('reff',1)
          let isKey = localStorage.getItem('isKey')
          let isOffid = localStorage.getItem('isOffid')
          // console.log('isKey',isKey)
          let officialId =localStorage.getItem('resFlog1')
          this.$store
            .dispatch("getCardInfo", {
              iccid: this.iccid,
              officialId
            })
            .then(() => {
              //! 权限
              localStorage.setItem('admin',this.iccid == '8986112128103181220'?1:0)//权限控制
              localStorage.setItem('adminTest',this.iccid == '8986112128103181220'?1:0)//权限控制

              let user = JSON.parse(localStorage.getItem('user-info'))
              // 更新用户微信信息
              if(user == null){console.log('new')}else{
                if(user.iccid != this.iccid){
                  setUserIccid(this.iccid,user.openid).then((res)=>{
                    console.log(res)
                    user.iccid = this.iccid
                    localStorage.setItem('user-info',JSON.stringify(user))// 更新本地用户信息 同步写入iccid
                  }).catch(()=>{})
                }
              }

              // login 2022-11-21
                // 写入用户数据
                // this.$store.dispatch("getWxUserInfo", {
                //   officialId:  localStorage.getItem('resFlog1'),
                //   code:  localStorage.getItem('codee')
                // });


                if(isKey =='null' && isOffid != 'null'){
                  alert('请扫描进入公众号充值！')
                }else{
                  this.$router.push({
                    name: "combo",
                    params: { officialId }
                  });
                }
            });
        }
        
    },
    scanLogin() {
      const _this = this;
      wechatUtil.init(["scanQRCode"]).then(wx => {
        wx.scanQRCode({
          desc: "scanQRCode desc",
          needResult: 1, // 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
          scanType: ["qrCode", "barCode"], // 可以指定扫二维码还是一维码，默认二者都有
          success: function(res) {
            // console.log(res)
            let result = res.resultStr; // 当needResult 为 1 时，扫码返回的结果
            let len = result.indexOf(",");
            if (len) {
              result = result.substring(len + 1);
            }
            if (result.length > 20) {
              len = result.lastIndexOf("=");
              if (len) {
                result = result.substring(len + 1);
              }
            }
            _this.iccid = result;
            _this.login();
          }
        });
      });
    }
  }
};
</script>
<style scoped>
.main {
  width: 100%;
  height: 100%;
  background-color: #f5f8fa !important;
}
.bg-top {
  padding-top: 1rem;
}
</style>
